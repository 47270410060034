const clients = {
    title: "Клієнти",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    table: {
        title: "Інформація про клієнтів",
        panel: {
            searchPlaceholder: "Пошук",
            archive: "Архів",
            create: "Створити клієнта",
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            name: "Ім'я / Назва компанії",
            type: "Тип клієнта",
            responsible: "Відповідальний",
            createdAt: "Створено",
            source: "Джерело",
            status: "Статус",
            email: "Пошта",
            contactPerson: "Контактна особа",
            phoneNumber: "Номер телефону",
            comment: "Коментар",
            actions: "Дії",
            novaPostDeliveryAddress: "Адреса нової пошти",
            novaPostDeliveryAddressCity: "Місто (нова пошта)",
            novaPostDeliveryAddressDepartment: "Відділення нової пошти",
            novaPostDeliveryAddressDepartmentType: "Тип відділення нової пошти",
            ukrPostDeliveryAddress: "Адреса укр пошти",
            ukrPostDeliveryAddressCity: "Місто (укр пошта)",
            ukrPostDeliveryAddressDepartment: "Відділення укр пошти",
            conversionTime: "Дата зміни статусу лід -> клієнт",
            tasks: "Завдання",
        },
    },
    modal: {
        header: "Створити клієнта з ліда",
        inputs: {
            lead: "Лід",
        },
        create: "Перевести до клієнта",
        leadData: "Дані обраного ліда",
    },
    filter: {
        header: "Фільтр клієнтів",
        inputs: {
            type: "Тип клієнта",
            responsibleUser: "Відповідальний",
            createdTo: "Створено до",
            createdFrom: "Створено від",
            source: "Джерело",
            status: "Статус",
        },
        placeholders: {
            name: "Введіть назву компанії",
            email: "Введіть пошту клієнта",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    card: {
        title: "Особиста картка клієнта",
        info: {
            title: "Інформація про клієнта",
            saveButton: "Зберегти",
        },
    },
    boardView: {
        noStatus: "Без статусу",
        copyLink: "Копіювати посилання",
        archive: "Архівувати",
        unArchive: "Розархівувати",
    },
};

export default clients;
