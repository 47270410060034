const tooltips = {
    copyLink: "Копіювати посилання",
    archive: "Архівувати",
    unarchive: "Розархівувати",
    edit: "Редагувати",
    cancelSelect: "Скасувати виділення",
    transformToClient: "Перетворити в клієнта",
    requestForArchive: "Запит на архівування",
    copy: "Копіювати",
    moveToCategory: "Перемістити до категорії",
    remove: "Видалити",
    printDeliveries: "Роздрукувати вибрані накладні",
    printRegistry: "Роздрукувати вибрані реєстри накладних",
    export: "Експортувати дані",
    import: "Імпортувати дані",
    addArticleToPdf: "ОПИСАТИ ПОВЕДІНКУ",
    addProducNameToPdf: "ОПИСАТИ ПОВЕДІНКУ",
    copyArticleToBarcode: "ОПИСАТИ ПОВЕДІНКУ",
};

export default tooltips;
