const deals = {
    title: "Deals",
    subtitle: "Track the essentials on the Dashboard",
    table: {
        title: "Information about the deals",
        panel: {
            searchPlaceholder: "Search",
            archive: "Archive",
            create: "Create deal",
            filter: "Filter",
        },
        columns: {
            id: "ID",
            name: "Name",
            amount: "Additional spendings",
            funnel: "Funnel",
            currency: "Currency",
            client: "Client",
            responsible: "Responsible",
            comment: "Comment",
            stage: "Stage",
            source: "Source",
            createdAt: "Created",
            actions: "Actions",
            endDate: "End date",
            responsibleContact: "Responsible contact",
            totalAmount: "Total amount",
            conversionTime: "Conversion time",
            novaPostDeliveryAddress: "Nova post address",
            ukrPostDeliveryAddress: "Ukrpost address",
            tasks: "Tasks",
            priceLists: "Price lists",
        },
    },
    card: {
        title: "Personal deal card",
        info: {
            title: "Information about the deal",
            saveButton: "Save",
        },
    },
    modal: {
        header: "Create a deal",
        inputs: {
            name: "Deal name *",
            nameError: "Name is incorrect",
            amount: "Amount",
            amountError: "Amount is incorrect",
            client: "Client *",
            responsibleUser: "Responsible *",
            comment: "Comment",
            stage: "Stage",
            source: "Source",
            endDate: "End date",
            responsibleContact: "Responsible contact",
            currency: "Currency",
            noResponsibleContact: "Client does not have additional contacts",
            noClientId: "Select client to add additional contacts",
            funnel: "Funnel",
        },
        create: "Create",
    },
    filter: {
        header: "Deals filter",
        inputs: {
            additionalSpendingFrom: "Additional spendings from",
            additionalSpendingTo: "Additional spendings to",
            amountFrom: "Total amount from",
            amountFromError: "Total amount is incorrect",
            amountTo: "Total amount to",
            amountToError: "Total amount is incorrect",
            currency: "Currency",
            client: "Client",
            responsibleUser: "Responsible",
            comment: "Comment",
            funnel: "Funnel",
            stage: "Stage",
            source: "Source",
            createdAtFrom: "Created at from",
            createdAtTo: "Created at to",
            endDateFrom: "End date from",
            endDateTo: "End date to",
            createdAt: "Created at",
            endDate: "End date",
        },
        placeholders: {
            name: "Type company name",
            currency: "Type currency name",
            amountFrom: "From",
            amountTo: "To",
            client: "Type client name",
            responsibleUser: "Type name",
        },
        apply: "Apply",
        clear: "Clear",
    },
    boardView: {
        noStage: "No stage",
        copyLink: "Copy link",
        archive: "Archive",
        unArchive: "Unarchive",
        card: {
            client: "Client",
            deal: "Deal",
        },
    },
};

export default deals;
