import Joi from "joi";
import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import { emailSchema, passwordSchema, phoneNumberSchema } from "../common";

const fullNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Поле ПІБ не може бути порожнім.",
        "string.max": "Поле ПІБ не може містити більше 100 символів.",
        "string.min": "Поле ПІБ не може містити менше 3 символів.",
        "any.required": "Поле ПІБ не може бути порожнє.",
    },
    [enUsLocaleName]: {
        "string.empty": "The full name field cannot be empty.",
        "string.max": "The full name cannot exceed 100 characters.",
        "string.min": "The full name must be at least 3 characters long.",
        "any.required": "The full name field is required.",
    },
};

const confirmPasswordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи.",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи.",
        "any.required": 'Значення цього поля має бути однакове із полем "пароль".',
        "any.only": 'Значення цього поля має бути однакове із полем "пароль".',
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "any.required": 'This field value must match the "password" field.',
        "any.only": 'This field value must match the "password" field.',
    },
};

export const updateUserProfileSchema = Joi.object({
    fullName: Joi.string().min(3).max(100).required().messages(fullNameErrMsgs),
    login: emailSchema.allow(""),
    phoneNumber: phoneNumberSchema.required(),
});

export const changePasswordInProfile = Joi.object({
    password: passwordSchema,
    newPassword: passwordSchema,
    newPasswordConfirm: Joi.when("newPassword", {
        is: passwordSchema,
        then: Joi.valid(Joi.ref("newPassword")).required(),
        otherwise: Joi.allow(""),
    }).messages(confirmPasswordErrMsgs),
});
