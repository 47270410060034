const modals = {
    general: {
        error: "Сталася помилка при обробці вашого запиту",
    },
    editingPhotos: {
        save: "Зберегти",
        cancel: "Скасувати",
        title: "Редагувати фото",
        requirements: "Приймаються .jpg, .jpeg, .png, .gif, макс. 10МБ, від 800x600px до 4000x3000px",
        inputPlaceholder: "Завантажити файл",
        inputLabel: "Фото",
        selectMainPhoto: "Вибрати головне фото",
    },
    printingRegistry: {
        title: "Додавання документа до реєстру в процесі",
        description: "Це може зайняти деякий час",
    },
    uploadingProgress: {
        title: "Файли завантажуються",
        description: "Це може зайняти деякий час",
        uploading: "Триває завантаження",
        uploaded: "Завантажено",
    },
    confirmDelete: {
        title: "Ви впевнені?",
        subtitle: "Дані буде видалено",
        decline: "Ні",
        accept: "Так",
    },
    moveOrCopyPriceList: {
        move: {
            title: "Перемістити до категорії",
            yes: "Перемістити",
            no: "Скасувати",
        },
        copy: {
            title: "Копіювати до категорії",
            yes: "Скопіювати",
            no: "Скасувати",
        },
    },
    inputEmail: {
        title: "Підключіть обліковий запис електронної пошти",
        inputLabel: "Електронна адреса",
        confirm: "Підтвердити",
        cancel: "Скасувати",
    },
    changesNotSavedModal: {
        title: "Ви впевнені, що хочете залишити сторінку?",
        subtitle: "Ваші зміни не збережені.",
        confirm: "Підтвердити",
        cancel: "Скасувати",
    },
};

export default modals;
