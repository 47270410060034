import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { emailSchema } from "../common";

const passwordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Поле паролю є обов'язковим.",
        "any.required": "Поле паролю є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.empty": "The password field is required.",
        "any.required": "The password field is required.",
    },
};

const hostErrMsgs = {
    [ukUaLocaleName]: {
        "string.hostname": "Введіть коректний хост (наприклад gmail.com чи icloud.com).",
        "string.empty": "Поле хоста є обов'язковим.",
        "any.required": "Поле хоста є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.hostname": "Please enter a valid host (e.g. gmail.com or icloud.com).",
        "string.empty": "The host field is required.",
        "any.required": "The host field is required.",
    },
};

const portErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Порт має бути числом.",
        "number.integer": "Порт має бути цілим числом.",
        "number.min": "Порт має бути від 1.",
        "number.max": "Порт має бути до 65535.",
        "any.required": "Поле порту є обов'язковим.",
    },
    [enUsLocaleName]: {
        "number.base": "The port must be a number.",
        "number.integer": "The port must be an integer.",
        "number.min": "The port must be at least 1.",
        "number.max": "The port must be no greater than 65535.",
        "any.required": "The port field is required.",
    },
};

const imapProviderErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Поле має бути текстовим.",
    },
    [enUsLocaleName]: {
        "string.base": "The field must be a string.",
    },
};

const senderNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Ім'я відправника має бути текстовим.",
        "string.empty": "Поле \"Ім'я відправника\" є обов'язковим.",
        "string.max": "Ім'я відправника має містити не більше 255 символів.",
    },
    [enUsLocaleName]: {
        "string.base": "The sender's name must be a string.",
        "string.empty": "The sender's name field is required.",
        "string.max": "The sender's name must be no longer than 255 characters.",
    },
};

export const newEmailAccountSchema = Joi.object({
    email: emailSchema.required(),
    password: Joi.string().required().messages(passwordErrMsgs),
    imapHost: Joi.string().hostname().required().messages(hostErrMsgs),
    imapPort: Joi.number().integer().min(1).max(65535).required().messages(portErrMsgs),
    smtpHost: Joi.string().hostname().required().messages(hostErrMsgs),
    smtpPort: Joi.number().integer().min(1).max(65535).required().messages(portErrMsgs),
    imapProvider: Joi.string().allow("").optional().messages(imapProviderErrMsgs),
    senderName: Joi.string().max(255).messages(senderNameErrMsgs),
});
