import Joi from "joi";
import { emailSchema, passwordSchema, phoneNumberSchema } from "../common";
import { RegexPresets } from "@/uikit/utils/validators/regex";
import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";

const passwordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи.",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи.",
        "string.pattern.base": "Пароль має містити мінімум 8 символів, 1 велику і 1 малу літеру.",
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "string.pattern.base": "The password must contain at least 8 characters, 1 uppercase letter, and 1 lowercase letter.",
    },
};

const fullNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": 'Поле "Ім\'я та прізвище" не може бути порожнім.',
        "string.max": 'Поле "Ім\'я та прізвище" не може містити більше 100 символів.',
        "string.min": 'Поле "Ім\'я та прізвище" не може містити менше 3 символів.',
        "any.required": "Поле \"Ім'я та прізвище\" є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.empty": 'The "Full Name" field cannot be empty.',
        "string.max": 'The "Full Name" field cannot exceed 100 characters.',
        "string.min": 'The "Full Name" field must be at least 3 characters long.',
        "any.required": 'The "Full Name" field is required.',
    },
};

const confirmPasswordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи.",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи.",
        "any.required": 'Значення цього поля має бути однакове з полем "Пароль".',
        "any.only": 'Значення цього поля має бути однакове з полем "Пароль".',
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "any.required": 'This field value must match the "Password" field.',
        "any.only": 'This field value must match the "Password" field.',
    },
};

const googleAuthCodeErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Код автентифікатора не може бути порожнім.",
        "string.length": "Код автентифікатора повинен містити рівно 6 символів.",
        "string.pattern.base": "Код автентифікатора повинен складатися лише з цифр.",
        "any.required": "Код автентифікатора є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.empty": "Authenticator code cannot be empty.",
        "string.length": "Authenticator code must be exactly 6 characters long.",
        "string.pattern.base": "Authenticator code must contain only digits.",
        "any.required": "Authenticator code is required.",
    },
};

export const loginSchema = Joi.object({
    login: emailSchema.required(),
    password: Joi.string().pattern(RegexPresets.newPassword).required().messages(passwordErrMsgs),
});

export const registerSchema = Joi.object({
    fullName: Joi.string().min(3).max(100).required().messages(fullNameErrMsgs),
    login: emailSchema.required(),
    phoneNumber: phoneNumberSchema.required(),
    password: passwordSchema,
    confirmPassword: Joi.when("password", {
        is: passwordSchema,
        then: Joi.valid(Joi.ref("password")).required(),
        otherwise: Joi.allow(""),
    }).messages(confirmPasswordErrMsgs),
});

export const resetPasswordSchema = Joi.object({
    newPassword: passwordSchema,
    newPasswordRepeat: Joi.when("newPassword", {
        is: passwordSchema,
        then: Joi.valid(Joi.ref("newPassword")).required(),
        otherwise: Joi.allow(""),
    }).messages(confirmPasswordErrMsgs),
});

export const googleAuthenticatorCodeSchema = Joi.string().pattern(/^\d+$/).length(6).required().messages(googleAuthCodeErrMsgs);
