import { ActionContext } from "vuex";
import { AxiosResponse } from "axios";

import { priceListService } from "@/services";
import {
    Category,
    PriceListFilters,
    PriceListSorting,
    CreatePriceList,
    UpdatePriceList,
    PriceList,
    UploadProgressHandlers,
    PriceListMultiAction,
    Locale,
    Barcode,
    BarcodeChangableFields,
    Pagination,
    DealSorting,
} from "@/services/types";
import { paginationState, paginationMutations, PaginationState, customFieldsState, customFieldsMutations, CustomFieldsState } from "@/store/mixins";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";
import { DownloadFileTypeOptions } from "@/utils/import-export";

type PriceListResponse = AxiosResponse<{ _: string }> | undefined;

export interface PriceListState extends CustomFieldsState, PaginationState {
    priceList: PriceList[];
    priceListItem: PriceList;
    categories: Partial<Category[]>;
    createPriceList: PriceListResponse;
    updatePriceList: PriceListResponse;
    routeQuery: any;
}

const PriceListModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
        ...paginationState,
        priceList: [] as PriceList[],
        priceListItem: undefined,
        categories: [] as Partial<Category[]>,
        createPriceList: undefined as PriceListResponse,
        updatePriceList: undefined as PriceListResponse,
        routeQuery: {},
    },
    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setPriceList: (state: PriceListState, payload: PriceList[]): void => {
            state.priceList = payload;
        },
        setPriceListItem: (state: PriceListState, payload: PriceList): void => {
            state.priceListItem = payload;
        },
        setCreatePriceList: (state: PriceListState, payload: PriceListResponse): void => {
            state.createPriceList = payload;
        },
        setUpdatePriceList: (state: PriceListState, payload: PriceListResponse): void => {
            state.updatePriceList = payload;
        },
        setRouteQuery: (state: PriceListState, payload: any): void => {
            state.routeQuery = payload;
        },
        updatePriceList: (state: PriceListState, payload: any): void => {
            state.routeQuery = payload;
        },
        upsertBarcode: (state: PriceListState, payload: Barcode): void => {
            state.priceListItem = { ...state.priceListItem, ...payload };
        },
        deleteBarcode: (state: PriceListState): void => {
            state.priceListItem = {
                ...state.priceListItem,
                barcode: null,
                barcodeFormat: null,
                barcodeComment: "",
                barcodeImg: null,
                barcodeImgLink: null,
                barcodePdfLink: null,
            };
        },
    },
    actions: {
        createBarcode: async ({ commit }: ActionContext<unknown, unknown>, payload: { priceListId: number; barcode: BarcodeChangableFields }) => {
            const createdBarCode = await priceListService.createBarcode(payload.priceListId, payload.barcode);
            commit("upsertBarcode", createdBarCode);
        },
        updateBarcode: async ({ commit }: ActionContext<unknown, unknown>, payload: { priceListId: number; barcode: BarcodeChangableFields }) => {
            const createdBarCode = await priceListService.updateBarcode(payload.priceListId, payload.barcode);
            commit("upsertBarcode", createdBarCode);
        },

        deleteBarcode: async ({ commit }: ActionContext<unknown, unknown>, id: number) => {
            await priceListService.deleteBarcode(id);
            commit("deleteBarcode");
        },
        getPriceList: async ({ commit }: ActionContext<unknown, unknown>, filters: PriceListFilters | PriceListSorting | Locale): Promise<void> => {
            // todo we have also getPriceListItem
            try {
                const { priceList, page, pagesCount, customFields } = (await priceListService.getPriceList(filters)).data;
                commit("setPriceList", priceList);
                commit("setCustomFields", customFields);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        createPriceList: async ({ commit }: ActionContext<unknown, unknown>, payload: { priceList: CreatePriceList; uploadHandlers: UploadProgressHandlers }) => {
            const { onUploadProgress, onSuccessUpload, onErrorUpload } = payload.uploadHandlers;

            commit("setCreatePriceList", undefined);
            try {
                const formData = new FormData();

                const { file, ...rest } = payload.priceList;

                for (const [key, value] of Object.entries(rest)) {
                    formData.append(key, String(value));
                }

                file.forEach(({ file, isMain }, index) => {
                    const nonNullableFile = file as NonNullable<typeof file>;

                    if (isMain) formData.append("mainPhotoFileIndex", String(index));
                    formData.append("files", nonNullableFile);
                });

                const res = await priceListService.createPriceList(formData, onUploadProgress);
                commit("setCreatePriceList", res);
                onSuccessUpload();
                return res;
            } catch (e: any) {
                onErrorUpload("");
                commit("setCreatePriceList", e.response || { statusText: e.code });
                throw e;
            }
        },
        getPriceListItem: async ({ commit }: ActionContext<unknown, unknown>, id: number): Promise<void> => {
            // todo we have also getPriceList
            const item = await priceListService.getPriceListById(id);
            commit("setPriceListItem", item);
        },
        setRouteQuery: async ({ commit }: ActionContext<unknown, unknown>, query: any): Promise<void> => {
            commit("setRouteQuery", query);
        },

        updatePriceList: async (
            { commit }: ActionContext<unknown, unknown>,
            payload: { id: number; updatedPriceList: UpdatePriceList; uploadHandlers: UploadProgressHandlers }
        ): Promise<void> => {
            commit("setUpdatePriceList", undefined);
            const {
                id,
                updatedPriceList,
                uploadHandlers: { onUploadProgress, onSuccessUpload, onErrorUpload },
            } = payload;
            const formData = new FormData();
            const { photos, photoIdsToRemove, customFields, ...fields } = updatedPriceList;
            const photoFiles = photos ? Object.values(photos).filter(Boolean) : [];

            try {
                photoFiles.forEach((el) => {
                    if (el.file) formData.append("files", el.file);
                });

                photoFiles.forEach((photo) => {
                    if (!photo.isMain) return;

                    if (photo.file) {
                        formData.append("newMainPhotoFileName", photo.file.name);
                    } else {
                        formData.append("newMainPhotoId", String(photo.id));
                    }
                });

                if (formData.get("newMainPhotoFileName") && formData.get("newMainPhotoId")) {
                    formData.append("newMainPhotoFileName", "");
                    formData.append("newMainPhotoId", "");
                }

                Object.entries(fields).forEach(([key, value]) => formData.append(key, String(value)));
                if (customFields) formData.append("customFields", JSON.stringify(customFields));
                formData.append("photoIdsToRemove", photoIdsToRemove ? photoIdsToRemove.join(",") : "");

                const res = await priceListService.updatePriceList(id, formData, onUploadProgress);

                commit("setUpdatePriceList", res);
                onSuccessUpload();
            } catch (e: any) {
                commit("setUpdatePriceList", e.response || { statusText: e.code });
                onErrorUpload("");
                throw e;
            } finally {
                photoFiles.forEach((photo) => {
                    if (photo.file) {
                        URL.revokeObjectURL(photo.url || "");
                    }
                });
            }
        },
        async bulkUpdate(_commit: unknown, data: PriceListMultiAction): Promise<void> {
            try {
                return priceListService.bulkUpdate(data);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const usePriceList = (filters: ComputedRef<PriceListFilters | PriceListSorting | { locale: "ua" | "en" }>, enabled = computed(() => true)) => {
    return useQuery({
        queryKey: ["getPriceList", filters],
        // @ts-expect-error error here
        queryFn: () => priceListService.getPriceList(filters.value).then((res) => res.data),
        enabled,
    });
};

export const useRewriteDealPriceLists = () => {
    return useMutation({
        mutationFn: ({ dealId, priceLists }: { dealId: number; priceLists: (PriceList & { amount: number })[] }) =>
            priceListService.rewriteDealPriceList(dealId, priceLists),
    });
};

export const usePriceListFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["price-lists/fields"],
        queryFn: () => priceListService.priceListFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useExport = () =>
    useMutation({
        mutationFn: (
            filters: { locale: "ua" | "en"; isArchived: boolean } & { essenceFieldIds: string[]; customFieldIds: number[] } & Omit<PriceListFilters, keyof Pagination> &
                DealSorting
        ) => priceListService.exportPriceLists(filters).then((res) => res.data),
    });

export const useImport = () =>
    useMutation({
        mutationFn: (data: { locale: "ua" | "en" } & { file: File; uniqueFieldId: string }) => priceListService.importPriceLists(data).then((res) => res.data),
    });

export const useImportTemplate = () =>
    useMutation({
        mutationFn: (fileType: DownloadFileTypeOptions) => priceListService.importPriceListsTemplate(fileType).then((res) => res.data),
    });

export default PriceListModule;
