const validationsErrors = {
    invalidFieldsTemplateSing: "Поле {fields} не валідне, будь ласка перевірте",
    invalidFieldsTemplatePlur: "Поля {fields} не валідні, будь ласка перевірте",
    auth: {
        invalidFields: {
            fullName: "Ім'я та прізвище",
            password: "Ваш пароль",
            phoneNumber: "Ваш номер телефону",
            confirmPassword: "Повторіть пароль",
            login: "Ваша пошт",
        },
    },
    lead: {
        invalidFields: {
            responsible: "відповідальний",
            typeId: "тип ліда",
            name: "ім'я",
            phoneNumber: "номер телефону",
            email: "пошта",
            contactPerson: "контактна особа",
        },
    },
    createLead: {
        invalidFields: {
            responsibleId: "відповідальний",
            typeId: "тип ліда",
            name: "ім'я",
            phoneNumber: "номер телефону",
            email: "пошта",
            contactPerson: "контактна особа",
        },
    },
    client: {
        invalidFields: {
            source: "джерело",
            email: "пошта",
            name: "Ім'я / Назва компанії ",
            phoneNumber: "номер телефону",
            type: "тип",
            status: "статус",
        },
    },
    deal: {
        invalidFields: {
            currencyId: "валюта",
            currency: "валюта",
            name: "назва угоди",
            amount: "сума",
            responsibleId: "відповідальний",
            clientId: "клієнт",
            stageId: "етап",
        },
    },
    task: {
        invalidFields: {
            name: "назва завдання",
            responsibleId: "головний відповідальний",
        },
    },
    priceList: {
        invalidFields: {
            name: "назва",
            unit: "одиниця виміру",
            cost: "собівартість",
            price: "ціна",
            url: "URL",
            currencyId: "валюта",
        },
    },
    user: {
        invalidFields: {
            fullName: "ПІБ",
            login: "пошта",
            roleId: "роль",
            positionId: "посада",
            confirmPassword: "повторіть пароль",
        },
    },
    additionalContacts: {
        invalidFields: {
            phoneNumber: "Телефон",
            email: "Email",
            firstName: "Ім'я",
            lastName: "Прізвище",
            middleName: "По-батькові",
        },
    },
};

export default validationsErrors;
