import { ref, onMounted, computed } from "vue";
import { newUUID } from "jssip/lib/Utils";
import moment from "moment";
import { useRootStore } from "@/utils/hooks/store";

export function useActiveTab() {
    const date = moment().format("YYYY-MM-DD");
    const tabId = `${date}-${newUUID()}`;
    const isActiveTab = computed(() => currentTabId.value === tabId);
    const currentTabId = ref();
    const channel = new BroadcastChannel("tabsChannel");
    const store = useRootStore();

    const setActiveTab = () => {
        localStorage.setItem("activeTabId", tabId);
        currentTabId.value = tabId;
        notifyTabs();
        store.commit("modals/setParams", { initSipConnection: true, closeSipConnection: false });
    };

    const notifyTabs = () =>
        channel.postMessage({
            type: "update-tabs",
            activeTabId: tabId,
        });

    channel.onmessage = (event) => {
        const { type, activeTabId } = event.data;
        if (type === "update-tabs") {
            currentTabId.value = activeTabId;
            if (!isActiveTab.value) {
                store.commit("modals/setParams", { closeSipConnection: true, initSipConnection: false });
            } else {
                store.commit("modals/setParams", { initSipConnection: true, closeSipConnection: false });
            }
        }
    };

    const handleTabClose = () => {
        store.commit("modals/setParams", { closeSipConnection: true, initSipConnection: false });
        const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
        const updatedTabs = storedTabs.filter((tab: string) => tab !== tabId && tab.startsWith(date));
        if (updatedTabs.length > 0) {
            localStorage.setItem("tabs", JSON.stringify(updatedTabs));
            if (isActiveTab.value) {
                localStorage.setItem("activeTabId", updatedTabs[updatedTabs.length - 1]);
            }
            notifyTabs();
        } else {
            localStorage.removeItem("tabs");
            localStorage.removeItem("activeTabId");
        }
    };

    onMounted(() => {
        const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
        storedTabs.push(tabId);
        localStorage.setItem("tabs", JSON.stringify(storedTabs));

        setActiveTab();

        window.addEventListener("beforeunload", handleTabClose);

        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                setActiveTab();
            }
        });
    });

    return isActiveTab;
}
