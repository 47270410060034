import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { filterNumber } from "../common";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Назва угоди не може бути порожня.",
        "string.max": "Назва угоди не може містити більше 100 символів",
        "string.min": "Назва угоди не може містити менше 3 символів",
    },
    [enUsLocaleName]: {
        "string.empty": "Deal name cannot be empty.",
        "string.max": "Deal name cannot exceed 100 characters.",
        "string.min": "Deal name cannot be less than 3 characters.",
    },
};

const clientIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "клієнт" є обов\'язковим.',
        "number.positive": 'Поле "клієнт" є обов\'язковим.',
        "number.integer": 'Поле "клієнт" є обов\'язковим.',
        "any.required": 'Поле "клієнт" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "client" field is required.',
        "number.positive": 'The "client" field is required.',
        "number.integer": "The client ID must be an integer.",
        "any.required": 'The "client" field is required.',
    },
};

const responsibleIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "відповідальний" є обов\'язковим.',
        "number.positive": 'Поле "відповідальний" є обов\'язковим.',
        "number.integer": 'Поле "відповідальний" є обов\'язковим.',
        "any.required": 'Поле "відповідальний" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "responsible" field is required.',
        "number.positive": 'The "responsible" field is required.',
        "number.integer": 'The "responsible" field is required.',
        "any.required": 'The "responsible" field is required.',
    },
};

const funnelIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "воронка" є обов\'язковим.',
        "number.positive": 'Поле "воронка" має бути позитивним числом.',
        "number.integer": "ID воронки має бути цілим числом.",
        "any.required": 'Поле "воронка" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "funnel" field is required.',
        "number.positive": 'The "funnel" field must be a positive number.',
        "number.integer": "The funnel ID must be an integer.",
        "any.required": 'The "funnel" field is required.',
    },
};

const stageIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "етап" є обов\'язковим.',
        "number.positive": 'Поле "етап" має бути позитивним числом.',
        "number.integer": "ID етапу має бути цілим числом.",
        "any.required": 'Поле "етап" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "stage" field is required.',
        "number.positive": 'The "stage" field must be a positive number.',
        "number.integer": "The stage ID must be an integer.",
        "any.required": 'The "stage" field is required.',
    },
};

const currencyErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "валюта" є обов\'язковим.',
        "number.positive": 'Поле "валюта" є обов\'язковим.',
        "number.integer": 'Поле "валюта" є обов\'язковим.',
        "string.empty": 'Поле "валюта" є обов\'язковим.',
        "string.base": 'Поле "валюта" є обов\'язковим.',
        "any.required": 'Поле "валюта" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "currency" field is required.',
        "number.positive": 'The "currency" field is required.',
        "number.integer": 'The "currency" field is required.',
        "string.empty": 'The "currency" field is required.',
        "string.base": 'The "currency" field is required.',
        "any.required": 'The "currency" field is required.',
    },
};

export const createDealSchema = Joi.object({
    name: Joi.string().min(3).max(100).required().messages(nameErrMsgs),
    amount: Joi.number().min(0).allow(0).optional(),
    currencyId: Joi.number().integer().positive().required().messages(currencyErrMsgs),
    responsibleId: Joi.number().integer().positive().required().messages(responsibleIdErrMsgs),
    responsibleContactId: Joi.number().integer().allow(-1).optional(),
    clientId: Joi.number().integer().positive().required().messages(clientIdErrMsgs),
    comment: Joi.string().allow("").optional(),
    stageId: Joi.number().integer().positive().required().messages(stageIdErrMsgs),
    funnelId: Joi.number().integer().positive().required().messages(funnelIdErrMsgs),
    sourceId: Joi.number().integer().allow(-1).optional(),
    endDate: Joi.date().optional(),
    createdAt: Joi.date().optional(),
});

export const updateDealSchema = Joi.object({
    name: Joi.string().min(3).max(100).required().messages(nameErrMsgs),
    amount: Joi.number().min(0).allow(0).optional(),
    currency: Joi.string().required().messages(currencyErrMsgs),
    responsible: Joi.string().optional().messages(responsibleIdErrMsgs),
    responsibleContact: Joi.optional(),
    client: Joi.string().required().messages(clientIdErrMsgs),
    comment: Joi.string().allow("").optional(),
    stageId: Joi.number().integer().positive().required().messages(stageIdErrMsgs),
    funnelId: Joi.number().required().messages(funnelIdErrMsgs),
    source: Joi.string().allow("").optional(),
    endDate: Joi.date().allow(null).optional(),
    createdAt: Joi.date().optional(),
});

export const dealsFiltersSchema = Joi.object({
    deals_additionalSpendingFrom: filterNumber,
    deals_additionalSpendingTo: filterNumber,
    deals_totalAmountFrom: filterNumber,
    deals_totalAmountTo: filterNumber,
});
