import { DeliveryProviders } from "@/services/enums";

const leads = {
    title: "Ліди",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    table: {
        title: "Інформація про лідів",
        panel: {
            searchPlaceholder: "Пошук",
            archive: "Архів",
            create: "Створити ліда",
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            name: "Ім'я / Назва компанії",
            type: "Тип ліда",
            responsible: "Відповідальний",
            createdAt: "Створено",
            source: "Джерело",
            status: "Статус",
            email: "Пошта",
            contactPerson: "Контактна особа",
            phoneNumber: "Номер телефону",
            comment: "Коментар",
            actions: "Дії",
            novaPostDeliveryAddress: "Адреса нової пошти",
            novaPostDeliveryAddressCity: "Місто (нова пошта)",
            novaPostDeliveryAddressDepartment: "Відділення нової пошти",
            novaPostDeliveryAddressDepartmentType: "Тип відділення нової пошти",
            ukrPostDeliveryAddress: "Адреса укр пошти",
            ukrPostDeliveryAddressCity: "Місто (укр пошта)",
            ukrPostDeliveryAddressDepartment: "Відділення укр пошти",
            firstName: "Прізвище",
            lastName: "Імʼя",
            middleName: "По-батькові",
            position: "Посада",
            tasks: "Завдання",
        },
    },
    modal: {
        header: "Створити ліда",
        inputs: {
            name: "Імʼя / Назва компанії *",
            nameError: "Назва не коректна",
            responsibleUser: "Відповідальний *",
            comment: "Коментар",
            email: "Пошта *",
            emailError: "Формат електронної пошти має бути {format}",
            phone: "Номер телефону *",
            phoneError: "Номер не коректний",
            contactPerson: "Контактна особа",
            contactPersonError: "Довжина імені контактної особи повинна бути більше або дорівнювати 3 символам",
            status: "Статус",
            typeId: "Тип ліда *",
            source: "Джерело",
            [DeliveryProviders.NOVA_POST]: {
                city: "Місто (нова пошта)",
                department: "Відділення нової пошти",
                departmentType: "Тип відділення нової пошти",
            },
            [DeliveryProviders.UKR_POST]: {
                city: "Місто (укр пошта)",
                department: "Відділення укр пошти",
            },
        },
        create: "Створити",
    },
    filter: {
        header: "Фільтр лідів",
        inputs: {
            type: "Тип ліда",
            responsibleUser: "Відповідальний",
            createdTo: "Створено до",
            createdFrom: "Створено від",
            source: "Джерело",
            status: "Статус",
        },
        placeholders: {
            name: "Введіть назву компанії",
            email: "Введіть пошту клієнта",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    card: {
        title: "Особиста картка ліда",
        info: {
            title: "Інформація про ліда",
            saveButton: "Зберегти",
        },
    },
    boardView: {
        noStatus: "Без статусу",
        copyLink: "Копіювати посилання",
        convertToClient: "Конвертувати в клієнта",
        archive: "Архівувати",
        unArchive: "Розархівувати",
    },
    convertModal: {
        convertToClient: "Сконвертувати цього ліда в клієнта?",
        dataNotice: "Усі дані з цих кастомних полів будуть видалені:",
    },
};

export default leads;
