import auth from "./auth";
import calls from "./calls";
import categories from "./categories";
import clients from "./clients";
import customFields from "./custom-fields";
import customerTypes from "./customer-types";
import deals from "./deals";
import leads from "./leads";
import notes from "./notes";
import priceLists from "./price-lists";
import roles from "./roles";
import sources from "./sources";
import statuses from "./statuses";
import tasks from "./tasks";
import users from "./users";
import sidebar from "./sidebar";
import logs from "./logs";
import inputs from "./inputs";
import multiActions from "./multi-actions";
import uikit from "./uikit";
import modals from "./modals";
import dashboard from "./dashboard";
import navbar from "./navbar";
import settings from "./settings";
import statistics from "./statistics";
import mail from "./mail";
import common from "./common";
import notifications from "./notifications";
import profile from "./profile";
import additionalContacts from "./additional-contacts";
import delivery from "./delivery";
import importExport from "./importExport";
import tooltips from "./tooltips";
import errors from "./errors";
import validationsErrors from "./validation-errors";

const messages = {
    auth,
    calls,
    categories,
    clients,
    customFields,
    customerTypes,
    deals,
    leads,
    notes,
    priceLists,
    roles,
    sources,
    statuses,
    tasks,
    users,
    sidebar,
    logs,
    inputs,
    multiActions,
    uikit,
    modals,
    dashboard,
    navbar,
    settings,
    statistics,
    mail,
    common,
    notifications,
    profile,
    additionalContacts,
    delivery,
    importExport,
    tooltips,
    errors,
    validationsErrors,
};

export default messages;
