import { BarcodesFormats } from "@/services/enums";
import Joi from "joi";
import { enUsLocaleName, ukUaLocaleName } from "../../constants";

const commonMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Штрих-код має бути текстовим рядком.",
        "string.empty": "Штрих-код не може бути порожнім.",
        "any.required": "Штрих-код є обов`язковим полем.",
    },
    [enUsLocaleName]: {
        "string.base": "Barcode must be a string.",
        "string.empty": "Barcode cannot be empty.",
        "any.required": "Barcode is a required field.",
    },
};
const code39Pattern = new RegExp("^[A-Z0-9 \\-\\.\\$/\\+%]*$");

const onlyNumbers = new RegExp("^[0-9]*$");
const alphabeticNumbersSpecialChars = new RegExp("^[\x20-\x7F]+$");

export const upsertBarcodeSchema = Joi.object({
    barcodeFormat: Joi.string()
        .valid(...Object.values(BarcodesFormats))
        .required(),
    barcode: Joi.string()
        .required()
        .when("barcodeFormat", {
            is: BarcodesFormats.EAN_8,
            then: Joi.string()
                .length(7)
                .pattern(onlyNumbers)
                .messages({
                    [ukUaLocaleName]: {
                        "string.length": "Штрих-код в форматі EAN-8 має складатися з 7 цифр.",
                        "string.pattern.base": "Штрих-код може містити лише цифри.",
                        ...commonMsgs[ukUaLocaleName],
                    },
                    [enUsLocaleName]: {
                        "string.length": "Barcode in format EAN-8 must be 7 digits long.",
                        "string.pattern.base": "Barcode can only contain digits.",
                        ...commonMsgs[enUsLocaleName],
                    },
                }),
        })
        .when("barcodeFormat", {
            is: BarcodesFormats.EAN_13,
            then: Joi.string()
                .length(12)
                .pattern(onlyNumbers)
                .messages({
                    [ukUaLocaleName]: {
                        "string.length": "Штрих-код в форматі EAN-13 має складатися з 12 цифр.",
                        "string.pattern.base": "Штрих-код може містити лише цифри.",
                        ...commonMsgs[ukUaLocaleName],
                    },
                    [enUsLocaleName]: {
                        "string.length": "Barcode in format EAN-13 must be 12 digits long.",
                        "string.pattern.base": "Barcode can only contain digits.",
                        ...commonMsgs[enUsLocaleName],
                    },
                }),
        })
        .when("barcodeFormat", {
            is: BarcodesFormats.CODE_128,
            then: Joi.string()
                .min(1)
                .max(128)
                .pattern(alphabeticNumbersSpecialChars)
                .messages({
                    [ukUaLocaleName]: {
                        "string.length": "Штрих-код в форматі Code128 може містити від 1 до 128 символів.",
                        "string.pattern.base": "Штрих-код може містити тільки ASCII символи.",
                        ...commonMsgs[ukUaLocaleName],
                    },
                    [enUsLocaleName]: {
                        "string.length": "Barcode in format Code128 must be from 1 to 128 symbols symbols long.",
                        "string.pattern.base": "Barcode can only contain ASCII symbols.",
                        ...commonMsgs[enUsLocaleName],
                    },
                }),
        })
        .when("barcodeFormat", {
            is: BarcodesFormats.CODE_39,
            then: Joi.string()
                .min(1)
                .max(20)
                .pattern(code39Pattern)
                .messages({
                    [ukUaLocaleName]: {
                        "string.length": "Штрих-код в форматі Code39 може містити від 1 до 20 символів.",
                        "string.pattern.base": "Штрих-код може містити лише великі літери, цифри та символи - . $ / + %",
                        ...commonMsgs[ukUaLocaleName],
                    },
                    [enUsLocaleName]: {
                        "string.length": "Barcode in format Code39 can be from 1 to 20 symbols long.",
                        "string.pattern.base": "Barcode can only contain uppercase letters, digits, and symbols: - . $ / + %",
                        ...commonMsgs[enUsLocaleName],
                    },
                }),
        }),
    barcodeComment: Joi.string().allow("").optional(),
});
