const common = {
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    search: "Search",
    continue: "Continue",
    back: "Back",
    waitForDownloadLinkTitle: "Waiting for the link to downloading",
    waitForDownloadLinkMsg: "When files will be ready, you will get a letter on",
    waitForDownloadLinkMsgLastPart: "email with a link to download them. Link will be valid for 10 minutes",
    in: "in",
    fileErrors: {
        maxFileSizeLimitTitle: "Can't upload this file",
        maxFileSizeLimitDescription: `Maximum file upload size is {fileSizeLimit}`,
        maxFilesCountLimitTitle: "Can't upload this files",
        maxFilesCountLimitDescription: `Maximum upload count is {fileAmountLimit} files`,
        fileNameDuplicatedTitle: "Selected the files with the same names",
    },
    text: "Text",
    link: "Link",
    instruction: "Instruction",
    breadcrumbs: {
        main: "Main",
        leads: "Leads",
        lead: "Lead",
        clients: "Clients",
        client: "Client",
        tasks: "Tasks",
        task: "Task",
        calls: "Calls",
        deals: "Deals",
        deal: "Deal",
        priceLists: "Price list",
        priceList: "Price list",
        statistic: "Statistic",
        delivery: "Delivery",
        mail: "Mail",
        message: "Message",
        settings: "Settings",
        userSettings: "Users",
        userSetting: "User",
        roleSettings: "Roles",
        leadSettings: "Leads",
        clientSettings: "Clients",
        dealSettings: "Deals",
        taskSettings: "Tasks",
        priceListSettings: "Price list",
        notifications: "Notifications",
        notificationSettings: "Notifications",
        integrationSettings: "Integrations",
        mailSettings: "Mail",
        personalSettings: "Personal",
        account: "Account",
        profile: "Profile",
        createDelivery: "Create delivery",
        translateParam: {
            ["nova-post"]: "Nova Poshta",
            inbox: "- Inbox",
            sent: "- Sent",
            drafts: "- Drafts",
            starred: "- Starred",
            archive: "- Archive",
            spam: "- Spam",
            trash: "- Trash",
        },
    },
};

export default common;
