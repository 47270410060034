import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";

const dealStageItemErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Поле має бути текстовим значенням.",
        "string.empty": "Поле не може бути порожнім.",
        "string.min": "Поле повинно містити не менше {#limit} символів.",
        "string.max": "Поле повинно містити не більше {#limit} символів.",
        "string.pattern.name": "Поле не може складатися лише з пробілів.",
        "any.required": "Поле є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "The field must be a string.",
        "string.empty": "The field cannot be empty.",
        "string.min": "The field must have at least {#limit} characters.",
        "string.max": "The field must have no more than {#limit} characters.",
        "string.pattern.name": "The field cannot consist only of spaces.",
        "any.required": "The field is required.",
    },
};

export const dealStageItemSchema = Joi.string().min(1).max(50).regex(/\S/, "not only spaces").required().messages(dealStageItemErrMsgs);
export const funnelSchema = dealStageItemSchema;
