import { AnySchema, ObjectSchema } from "joi";
import { computed, ComputedRef, isReactive, Reactive, Ref, ref, ShallowRef } from "vue";
import { useI18n } from "vue-i18n";

type FormErrors = {
    fieldsErrors: Record<string, string>;
    isValidForm: boolean;
    formErrMsg: string;
    isSubmitted: boolean;
    onSubmit: () => any;
    reset: () => void;
};

type AcceptableRecord = Record<string, any>;
type FieldValidationTargetObj = Ref<any> | ComputedRef<any> | ShallowRef<any>;
type FormValidationTargetObj = Ref<AcceptableRecord> | ComputedRef<AcceptableRecord> | ShallowRef<AcceptableRecord> | Reactive<AcceptableRecord>;

type Options = {
    i18n: {
        sourceType: string;
    };
};

export const useFormValidation = (schema: ObjectSchema, targetObj: FormValidationTargetObj, options?: Options): ComputedRef<FormErrors> => {
    const { t, locale } = useI18n();
    const isSubmitted = ref(false);

    const createFormErr = (fields: string[]): string => {
        const pluralForm = fields.length > 1 ? "invalidFieldsTemplatePlur" : "invalidFieldsTemplateSing";
        const translatedFields = fields.map((f) => `"${t(`validationsErrors.${options?.i18n?.sourceType}.invalidFields.${f}`)}"`).join(", ");

        return fields.length ? t(`validationsErrors.${pluralForm}`, { fields: translatedFields }) : "";
    };

    return computed<FormErrors>(() => {
        const res = schema.validate(isReactive(targetObj) ? targetObj : targetObj.value, {
            abortEarly: false,
            allowUnknown: true,
            errors: {
                language: locale.value,
            },
        });

        const { error } = res;

        const invalidFields: string[] = [];
        const fieldsErrors =
            error?.details?.reduce<Record<string, string>>((acc, el) => {
                const key = el.path.join(".");
                acc[key] = el.message;

                invalidFields.push(key);

                return acc;
            }, {}) || {};

        const formErrMsg = createFormErr(invalidFields);
        const onSubmit = () => {
            isSubmitted.value = true;
        };

        const reset = () => {
            isSubmitted.value = false;
        };

        return { fieldsErrors, isValidForm: !error, formErrMsg, isSubmitted: isSubmitted.value, onSubmit, reset };
    });
};

type FieldError = {
    error: string;
    isSubmitted: boolean;
    onSubmit: () => any;
    reset: () => void;
};

export const useFieldValidation = (schema: AnySchema, targetValue: FieldValidationTargetObj): ComputedRef<FieldError> => {
    const { locale } = useI18n();
    const isSubmitted = ref(false);

    return computed<FieldError>(() => {
        const res = schema.validate(targetValue.value, {
            abortEarly: false,
            allowUnknown: true,
            errors: {
                language: locale.value,
            },
        });

        const { error } = res;

        const onSubmit = () => (isSubmitted.value = true);
        const reset = () => (isSubmitted.value = false);

        return { error: error?.details[0].message || "", isSubmitted: isSubmitted.value, onSubmit, reset };
    });
};
