const common = {
    cancel: "Скасувати",
    save: "Зберегти",
    add: "Додати",
    search: "Пошук",
    continue: "Продовжити",
    back: "Назад",
    waitForDownloadLinkTitle: "Очікуйте на посилання для завантаження",
    waitForDownloadLinkMsg: "Коли файли будуть готові, ви отримаєте лист на електронну пошту",
    waitForDownloadLinkMsgLastPart: "з посиланням для їх завантаження. Посилання буде дійсне протягом 10 хвилин.",
    in: "в",
    fileErrors: {
        maxFileSizeLimitTitle: "Не вдалось завантажити файл",
        maxFileSizeLimitDescription: `Максимальний розмір файла {fileSizeLimit}`,
        maxFilesCountLimitTitle: "Не вдалось завантажити файл",
        maxFilesCountLimitDescription: `Максимальна кількість файлів {fileAmountLimit}`,
        fileNameDuplicatedTitle: "Обрано файли з однаковою назвою",
    },
    text: "Назва",
    link: "Посилання",
    instruction: "Інструкція",
    breadcrumbs: {
        main: "Головна",
        leads: "Ліди",
        lead: "Лід",
        clients: "Клієнти",
        client: "Клієнт",
        tasks: "Завдання",
        task: "Завдання",
        calls: "Дзвінки",
        deals: "Угоди",
        deal: "Угода",
        priceLists: "Прайсліст",
        priceList: "Прайсліст",
        statistic: "Статистика",
        delivery: "Доставка",
        mail: "Пошта",
        message: "Повідомлення",
        settings: "Налаштування",
        userSettings: "Користувачі",
        userSetting: "Користувач",
        roleSettings: "Ролі",
        leadSettings: "Ліди",
        clientSettings: "Клієнти",
        dealSettings: "Угоди",
        taskSettings: "Завдання",
        priceListSettings: "Прайсліст",
        notifications: "Сповіщення",
        notificationSettings: "Сповіщення",
        integrationSettings: "Інтеграції",
        mailSettings: "Пошта",
        personalSettings: "Персональні",
        account: "Акаунт",
        profile: "Профіль",
        createDelivery: "Створити доставку",
        translateParam: {
            ["nova-post"]: "Нова Пошта",
            inbox: "- Вхідні",
            sent: "- Надіслані",
            drafts: "- Чернетки",
            starred: "- Із зірочкою",
            archive: "- Архів",
            spam: "- Спам",
            trash: "- Кошик",
        },
    },
};

export default common;
