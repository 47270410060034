const notifications = {
    title: "Notifications",
    emptyList: "No new notifications",
    showAll: "Show all",
    tabTitles: {
        messages: "Messages",
        showAll: "Show all",
    },
    convertedInClient: "Converted lead Id {leadId} into client Id {clientId}",
};

export default notifications;
